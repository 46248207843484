<template> 
  <card
    v-model="item"
    @openAuth="$emit('openAuth')"
    @delete="$emit('delete')"
    @refresh="$emit('refresh')"
    @getExternalLink="$emit('getExternalLink')"
  >
    <template #header>
      <b-row align-v="center" class="d-flex flex-nowrap justify-content-between w-100">

        <svg xmlns="http://www.w3.org/2000/svg" class="tri-svg-logo-header ml-1" viewBox="0 0 30 27" style="enable-background:new 0 0 100 100" xml:space="preserve">
          <g fill="none">
            <path d="M27.784 16.327c-.759 0-1.375.605-1.375 1.353 0 .746.616 1.352 1.375 1.352s1.375-.606 
              1.375-1.352c0-.748-.615-1.353-1.375-1.353zm-1.744 3.511c-.644 0-1.166.514-1.166 1.147 0 .634.522 1.148 1.166 1.148.643 0 1.165-.514 1.165-1.148 
              0-.633-.522-1.147-1.165-1.147zm-2.59 2.751a1 1 0 0 0-1.009.992 1 1 0 0 0 1.008.993 1 1 0 0 0 1.009-.993 1 1 0 0 0-1.009-.992zm-2.908 1.851a.812.812 0 0 0-.82.806c0 
              .445.367.806.82.806s.818-.361.818-.806a.812.812 0 0 0-.818-.806zm-2.86.98a.697.697 0 0 0-.702.69c0 .383.314.693.702.693.388 0 .703-.31.703-.692a.697.697 0 0 
              0-.703-.692zm-2.678.389a.6.6 0 0 0-.605.595.6.6 0 0 0 .605.596.6.6 0 0 0 .604-.596.6.6 0 0 0-.604-.595zm-2.683-.389a.697.697 0 0 0-.703.692c0 .382.315.692.703.692.388 
              0 .703-.31.703-.692a.697.697 0 0 0-.703-.692zm-2.86-.98c-.45 0-.816.359-.816.802s.366.804.816.804c.451 0 .816-.36.816-.804s-.365-.803-.816-.803zm-2.91-1.848a1 1 0 
              0 0-1.007.991 1 1 0 0 0 1.007.992 1 1 0 0 0 1.008-.992 1 1 0 0 0-1.008-.991zm-2.587-2.753c-.644 0-1.167.515-1.167 1.149s.523 1.148 1.167 1.148 1.167-.514 
              1.167-1.148c0-.634-.523-1.149-1.167-1.149zm-1.745-3.515c-.758 0-1.373.605-1.373 1.352 0 .747.615 1.352 1.373 1.352.76 0 1.374-.605 1.374-1.352 
              0-.747-.615-1.352-1.374-1.352zm-.63-4.386c-.878 0-1.589.7-1.589 1.564 0 .864.711 1.564 1.59 1.564.877 0 1.588-.7 1.588-1.564 
              0-.864-.711-1.564-1.589-1.564zm.63-3.962c-.758 0-1.373.605-1.373 1.351s.615 1.351 1.373 1.351 1.372-.605 
              1.372-1.35c0-.747-.614-1.352-1.372-1.352zm1.743-3.11c-.644 0-1.167.515-1.167 1.15s.523 1.148 1.167 1.148c.645 0 
              1.167-.514 1.167-1.149s-.522-1.149-1.167-1.149zm2.591-2.439a1 1 0 0 0-1.007.991 1 1 0 0 0 1.007.992 1 1 0 0 0 1.008-.992 
              1 1 0 0 0-1.008-.991zM9.462.955a.81.81 0 0 0-.816.803.81.81 0 0 0 .816.803.81.81 0 0 0 .815-.803.809.809 0 0 0-.815-.803zm2.857-.757c-.388 
              0-.703.31-.703.692s.315.692.703.692c.39 0 .704-.31.704-.692s-.315-.692-.704-.692zM14.998 0c-.334 0-.604.266-.604.594s.27.595.604.595c.333 0 
              .604-.267.604-.595S15.332 0 14.998 0zm2.687.2a.697.697 0 0 0-.703.691c0 .382.315.691.703.691.387 0 .701-.31.701-.69A.697.697 0 0 0 
              17.685.2zm2.857.756c-.45 0-.816.36-.816.802 0 .443.365.802.816.802.45 0 .815-.359.815-.802a.809.809 0 0 0-.815-.802zm2.905 1.474a.999.999 0 0 
              0-1.007.991c0 .547.45.99 1.007.99a.999.999 0 0 0 1.006-.99c0-.547-.45-.99-1.006-.99zm2.59 2.437a1.16 1.16 0 0 0-1.168 1.15c0 .635.524 1.15 
              1.169 1.15a1.16 1.16 0 0 0 1.168-1.15 1.16 1.16 0 0 0-1.168-1.15zm1.746 3.105c-.757 0-1.372.605-1.372 1.35 0 .747.615 1.352 1.372 1.352.759 0 
              1.373-.605 1.373-1.351s-.614-1.351-1.373-1.351zm-6.298 7.595a.612.612 0 0 0-.616.607c0 .336.276.607.616.607.341 0 .617-.271.617-.607a.612.612 
              0 0 0-.617-.607zm-1.54 2.25a.612.612 0 0 0-.617.607c0 .335.276.607.617.607s.617-.272.617-.607a.612.612 0 0 0-.617-.607zm-2.283 1.445c-.37 
              0-.67.295-.67.659 0 .364.3.659.67.659.37 0 .67-.295.67-.66 0-.363-.3-.658-.67-.658zm-2.7.393a.895.895 0 0 0-.903.888c0 
              .49.404.887.902.887s.902-.398.902-.887a.894.894 0 0 0-.902-.888zm-2.693-.53c-.566 0-1.024.45-1.024 1.007 0 .556.458 1.007 1.024 1.007.565 0 
              1.023-.45 1.023-1.007 0-.557-.458-1.007-1.023-1.007zm-2.445-1.697c-.627 0-1.135.5-1.135 1.118 0 .617.508 1.117 1.135 1.117s1.135-.5 
              1.135-1.117-.508-1.118-1.135-1.118zm-1.61-2.27a.945.945 0 0 0-.954.938c0 .518.427.937.953.937s.953-.42.953-.937a.946.946 0 0 0-.953-.938zm-.378-2.496a.732.732 
              0 0 0-.738.727c0 .4.33.726.738.726a.732.732 0 0 0 .737-.726.732.732 0 0 0-.737-.727zm.649-2.439a.615.615 0 0 0-.62.61c0 .336.278.609.62.609a.614.614 0 0 0 
              .619-.61.614.614 0 0 0-.62-.609zm1.54-2.227a.612.612 0 0 0-.616.607c0 .335.276.606.617.606.34 0 .616-.271.616-.606a.612.612 0 0 0-.616-.607zm2.28-1.56c-.369 
              0-.669.296-.669.66s.3.659.67.659c.37 0 .67-.295.67-.66s-.3-.659-.67-.659zm2.7-.851a.888.888 0 0 0-.895.881c0 .487.4.882.895.882a.888.888 0 0 0 
              .896-.882c0-.487-.401-.881-.896-.881zm2.7.292c-.563 0-1.02.45-1.02 1.004s.457 1.004 1.02 1.004c.564 0 1.02-.45 1.02-1.004s-.456-1.004-1.02-1.004zm2.433 
              1.472c-.628 0-1.137.502-1.137 1.12 0 .618.509 1.12 1.137 1.12s1.138-.502 1.138-1.12c0-.618-.51-1.12-1.138-1.12zm1.613 2.63a.944.944 0 0 0-.952.937c0 
              .517.426.936.952.936.525 0 .951-.42.951-.936a.944.944 0 0 0-.951-.937zm-2.478 4.114c-.315 0-.57.251-.57.56s.255.56.57.56c.314 0 
              .569-.25.569-.56s-.255-.56-.57-.56zm-1.105 1.732a.84.84 0 0 0-.847.833c0 .46.38.833.847.833s.846-.373.846-.833a.84.84 0 0 0-.846-.833zm-2.177 
              1.133a.88.88 0 0 0-.886.872c0 .482.397.873.886.873s.887-.39.887-.873a.88.88 0 0 0-.887-.872zm-2.547-.064a.78.78 0 0 0-.786.774.78.78 0 0 0 
              .786.773.78.78 0 0 0 .786-.773.78.78 0 0 0-.786-.774zm-1.893-1.263a.572.572 0 0 0-.577.567c0 .314.258.568.577.568a.572.572 0 0 0 
              .577-.568.572.572 0 0 0-.577-.567zm-.752-1.637a.415.415 0 0 0-.418.412c0 .227.188.412.418.412a.415.415 0 0 0 .42-.412.415.415 0 0 
              0-.42-.412zm-.043-2.133a.564.564 0 0 0-.57.56c0 .309.255.56.57.56.314 0 .568-.251.568-.56 0-.31-.254-.56-.568-.56zm1.108-2.283a.838.838 0 
              0 0-.844.83c0 .46.378.832.844.832a.838.838 0 0 0 .844-.831.838.838 0 0 0-.844-.831zm2.164-1.214c-.49 0-.888.391-.888.874s.397.873.888.873c.49 
              0 .887-.39.887-.873s-.397-.874-.887-.874zm2.554.279a.78.78 0 0 0-.788.774c0 .428.353.775.788.775a.781.781 0 0 0 .787-.775.78.78 0 0 0-.787-.774zm1.882 
              1.648a.572.572 0 0 0-.576.567c0 .314.258.568.576.568s.577-.254.577-.568a.572.572 0 0 0-.577-.567zm.793 1.962a.416.416 0 0 0-.42.413c0 
              .227.188.412.42.412.23 0 .419-.185.419-.412a.416.416 0 0 0-.42-.413zm2.865.648c-.409 0-.74.327-.74.73 0 .402.331.728.74.728.41 0 
              .74-.326.74-.729a.735.735 0 0 0-.74-.729zm3.056.883a.774.774 0 0 0-.78.768c0 .424.348.768.78.768.43 0 .78-.344.78-.768a.774.774 0 0 
              0-.78-.768zm-.774 2.712a.733.733 0 0 0-.739.727c0 .402.33.727.739.727s.739-.325.739-.727a.733.733 0 0 0-.74-.727zm-1.33 2.276a.619.619 
              0 0 0-.624.614c0 .339.279.614.623.614a.619.619 0 0 0 .624-.614.619.619 0 0 0-.624-.614zm-1.597 1.691a.535.535 0 0 0-.538.53c0 
              .293.24.531.538.531a.535.535 0 0 0 .54-.53.535.535 0 0 0-.54-.53zm-1.686 1.234a.458.458 0 0 0-.462.455c0 .25.207.454.462.454a.458.458 0 0 
              0 .462-.454.458.458 0 0 0-.462-.455zm-1.908.697a.534.534 0 0 0-.538.53c0 .292.24.529.538.529a.533.533 0 0 0 
              .537-.53c0-.292-.24-.529-.537-.529zm-2.243.36a.616.616 0 0 0-.62.612c0 .337.278.611.62.611a.616.616 0 0 0 .622-.611.616.616 0 0 0-.622-.612zm-2.565-.25a.802.802 
              0 0 0-.808.796c0 .439.362.795.808.795s.808-.356.808-.795a.801.801 0 0 0-.808-.795zm-2.697-.953a.947.947 0 0 0-.954.939c0 .519.427.94.954.94.527 0 
              .954-.421.954-.94a.947.947 0 0 0-.954-.94zm-2.39-1.764c-.618 0-1.12.494-1.12 1.103 0 .61.502 1.103 1.12 1.103.62 0 1.121-.494 1.121-1.103 
              0-.61-.501-1.103-1.12-1.103zm-1.982-2.662c-.67 0-1.213.535-1.213 1.194 0 .66.543 1.195 1.213 1.195s1.214-.535 
              1.214-1.195-.543-1.194-1.214-1.194zm-1.126-2.77a.948.948 0 0 0-.956.94c0 .52.428.94.956.94a.949.949 0 0 0 
              .956-.94c0-.52-.428-.94-.956-.94zm-.054-2.677a.778.778 0 0 0-.784.771c0 .427.35.772.784.772a.778.778 0 0 0 
              .784-.772.778.778 0 0 0-.784-.771zm.77-2.628a.733.733 0 0 0-.739.728c0 .401.33.727.74.727.408 0 
              .739-.326.739-.727a.734.734 0 0 0-.74-.728zM6.93 7.005a.617.617 0 0 0-.622.613c0 .338.279.612.622.612a.617.617 0 0 0 
              .622-.612.617.617 0 0 0-.622-.613zm1.594-1.524a.533.533 0 0 0-.537.529c0 .292.24.529.537.529a.533.533 0 0 0 
              .537-.529.533.533 0 0 0-.537-.529zm1.686-1.089a.46.46 0 0 0-.463.456.46.46 0 0 0 .463.455.46.46 0 0 0 .463-.455.46.46 0 0 
              0-.463-.456zm1.91-.839a.532.532 0 0 0-.536.528c0 .291.24.528.537.528a.533.533 0 0 0 .536-.528.532.532 0 0 0-.536-.528zm2.236-.53a.619.619 0 0 
              0-.623.613c0 .339.28.613.623.613a.619.619 0 0 0 .624-.613.619.619 0 0 0-.624-.614zm2.57-.112c-.447 0-.809.356-.809.795s.362.797.809.797c.447 
              0 .809-.357.809-.797s-.362-.795-.809-.795zm2.622.61a.95.95 0 0 0-.956.94c0 .52.428.942.956.942a.949.949 0 0 0 .956-.942.949.949 0 0 
              0-.956-.94zM22.024 5c-.62 0-1.122.495-1.122 1.104s.503 1.105 1.122 1.105c.62 0 1.122-.495 1.122-1.105s-.502-1.104-1.122-1.104zm1.968 
              2.488c-.67 0-1.214.535-1.214 1.194 0 .66.543 1.195 1.214 1.195s1.214-.535 1.214-1.195-.543-1.194-1.214-1.194zm1.127 3.279c-.527 
              0-.954.42-.954.94s.427.939.954.939c.528 0 .955-.42.955-.94s-.427-.94-.955-.94zM30 13.502c0 .864-.711 1.564-1.59 1.564-.877 
              0-1.588-.7-1.588-1.564 0-.864.711-1.564 1.589-1.564s1.589.7 1.589 1.564z" fill="#fa6400"
            />
          </g>
        </svg>
        
        <h2 class="px-1 d-flex flex-nowrap">
          <span>
            Thomson Reuters
          </span>
        </h2>
        <feather-icon
          :icon="active ? 'CheckIcon' : 'XIcon'"
          :class="active ? 'text-primary' : 'text-danger'" 
          size="30"
        />
      </b-row>
    </template>
  </card>
</template>

<script>
import { BImg, BIcon, BRow, BCol } from 'bootstrap-vue'
import Card from '@/layouts/components/ThirdPartyApps/Card.vue'

  export default {
    components: {
      BImg,
      BIcon,
      BRow,
      BCol,
      Card
    },
    props: {
      value: {
        type: Object,
        default: undefined,
      }
    },
    computed: {
      item: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      active() {
        return this.item.token
      }
    }
  }
</script>

<style lang="scss" scoped>
.tri-svg-logo-header{
  display: inline;
  height: 3em;
  
}
</style>